var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('go-back-btn',{attrs:{"routeToGo":_vm.routeToGo}}),_c('page-header',{staticClass:"mb-3",attrs:{"title":_vm.title + _vm.clienteNombre}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.contactosCliente,"single-expand":_vm.singleExpand,"search":_vm.search,"footer-props":{ 'items-per-page-text': 'Contactos por página' },"expanded":_vm.expanded,"item-key":"contactoId","show-expand":"","loading":_vm.isLoading},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar contacto","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"pt-5 text-right",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"text-right mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Nuevo ")])],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Observaciones:")]),_vm._v(" "+_vm._s(item.descripcion)+" ")])]}},{key:"item.informarActualizacion",fn:function(ref){
var item = ref.item;
return [(item.informarActualizacion)?_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModal(item.contactoId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar contacto")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModalDeleteContacto(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar contacto")])])]}}],null,true)}),(_vm.openModalEdit)?_c('v-dialog',{attrs:{"max-width":"50%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload(false)}},model:{value:(_vm.openModalEdit),callback:function ($$v) {_vm.openModalEdit=$$v},expression:"openModalEdit"}},[_c('EditContactoCliente',{attrs:{"contactoId":_vm.contactoId,"clienteId":_vm.clienteId},on:{"closeAndReload":function($event){return _vm.closeAndReload($event)}}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"max-width":"30%","titleProp":_vm.titleDelete,"openDelete":_vm.showDeleteModal,"isLoading":_vm.isLoadingDelete},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }