<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" />
    <page-header class="mb-3" :title="title + clienteNombre" />
    <v-data-table
      :headers="headers"
      :items="contactosCliente"
      :single-expand="singleExpand"
      :search="search"
      :footer-props="{ 'items-per-page-text': 'Contactos por página' }"
      :expanded.sync="expanded"
      item-key="contactoId"
      show-expand
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar contacto"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1"> </v-col>
            <v-col cols="3" class="pt-5 text-right">
              <v-btn
                color="primary"
                @click="openModal()"
                class="text-right mr-2"
              >
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <strong>Observaciones:</strong>
          {{ item.descripcion }}
        </td>
      </template>
      <template v-slot:[`item.informarActualizacion`]="{ item }">
        <v-icon
          v-if="item.informarActualizacion"
          small
          color="primary"
          class="d-flex justify-center"
        >
          {{ checkIcon }}
        </v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="20"
              @click="openModal(item.contactoId)"
            >
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar contacto</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="20"
              @click="openModalDeleteContacto(item)"
            >
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Eliminar contacto</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="50%"
      persistent
      @keydown.esc="closeAndReload(false)"
    >
      <EditContactoCliente
        :contactoId="contactoId"
        :clienteId="clienteId"
        @closeAndReload="closeAndReload($event)"
      ></EditContactoCliente>
    </v-dialog>
    <DeleteDialog
      max-width="30%"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      :isLoading="isLoadingDelete"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import EditContactoCliente from "./EditContactoCliente.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  components: { PageHeader, GoBackBtn, EditContactoCliente, DeleteDialog },
  directives: { mask },
  name: "ContactosClientes",

  data() {
    return {
      rules: rules,
      isLoading: false,
      dialog: false,
      expanded: [],
      servidorSistema: [],
      isFormValid: false,
      contactosCliente: [],
      formEditTitle: "Editar servidor del cliente: ",
      search: "",
      dialogDelete: false,
      routeToGo: "/clientes",
      singleExpand: true,
      date: null,
      dateFormatted: null,
      menu: false,
      title: "Contactos ",
      headers: [
        { text: "Nombre", value: "contactoNombre", sortable: false },
        {
          text: "Informar actualización",
          value: "informarActualizacion",
          sortable: false,
          align: "center",
        },
        { text: "Email", value: "email", sortable: false },
        { text: "Teléfono", value: "telefono", sortable: false },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      clienteId: null,
      clienteNombre: null,
      descripcion: null,
      servidorSelected: null,
      canFechaCorte: false,
      checkIcon: enums.icons.CHECK_OUTLINE,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      openModalEdit: false,
      contactoId: null,
      titleDelete: "¿Eliminar contacto?",
      showDeleteModal: false,
      idToDelete: null,
      isLoadingDelete: false,
    };
  },
  created() {
    this.clienteId = this.$route.params.itemsClientes.clienteId;
    this.clienteNombre = this.$route.params.itemsClientes.clienteNombre;
    this.loadContactos();
  },

  methods: {
    ...mapActions({
      getContactosByCliente: "clientesData/getContactosByCliente",
      deleteContactoCliente: "clientesData/deleteContactoCliente",
      setAlert: "user/setAlert",
    }),
    async loadContactos() {
      this.isLoading = true;
      try {
        const responseaxios = await this.getContactosByCliente(this.clienteId);
        this.contactosCliente = responseaxios;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    openModal(id) {
      this.contactoId = id;
      this.openModalEdit = true;
    },
    closeAndReload(event) {
      this.openModalEdit = false;
      if (event) this.loadContactos();
    },
    openModalDeleteContacto(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.contactoId;
    },
    async confirmDelete() {
      this.isLoadingDelete = true;
      try {
        const response = await this.deleteContactoCliente(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.loadContactos();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingDelete = false;
    },
  },
};
</script>
<style scoped>
::v-deep .myLabel {
  color: white;
}
</style>
