var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"py-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',{staticClass:"pb-2"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Nombre","outlined":"","rules":_vm.rules.required.concat(
                [_vm.rules.maxLength(_vm.contactoNombre, 50)],
                [_vm.rules.requiredTrim(_vm.contactoNombre)]
              )},model:{value:(_vm.contactoNombre),callback:function ($$v) {_vm.contactoNombre=$$v},expression:"contactoNombre"}})],1),_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"6"}},[_c('v-subheader',{staticClass:"py-0 px-0"},[_c('v-switch',{staticClass:"ml-4",attrs:{"dense":"","outlined":""},model:{value:(_vm.informarActualizacion),callback:function ($$v) {_vm.informarActualizacion=$$v},expression:"informarActualizacion"}}),_vm._v(" Informar actualización ")],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Email","outlined":"","rules":_vm.rules.required.concat([
                _vm.rules.requiredTrim(_vm.contactoEmail),
                _vm.rules.maxLength(_vm.contactoEmail, 50),
                _vm.rules.validEmail ])},model:{value:(_vm.contactoEmail),callback:function ($$v) {_vm.contactoEmail=$$v},expression:"contactoEmail"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Teléfono","outlined":"","rules":_vm.rules.required.concat(
                [_vm.rules.maxLength(_vm.contactoTelefono, 50)],
                [_vm.rules.requiredTrim(_vm.contactoTelefono)]
              )},model:{value:(_vm.contactoTelefono),callback:function ($$v) {_vm.contactoTelefono=$$v},expression:"contactoTelefono"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Observaciones","dense":"","auto-grow":"","rows":"1","rules":[_vm.rules.maxLength(_vm.observaciones, 100)],"outlined":""},model:{value:(_vm.observaciones),callback:function ($$v) {_vm.observaciones=$$v},expression:"observaciones"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.close()}}},[_c('span',{attrs:{"align":"center"}},[_vm._v(" Cancelar ")])]),_c('v-btn',{attrs:{"color":"#195472","type":"submit","disabled":!_vm.isFormValid,"form":"form","loading":_vm.isLoadingSaveBtn}},[_c('span',{staticClass:"myLabel",attrs:{"align":"center"}},[_vm._v("Guardar")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }