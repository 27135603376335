<template>
  <v-card outlined>
    <v-card-title>
      <span class="py-1 primary--text">{{ formEditTitle }}</span>
    </v-card-title>
    <v-card-text class="pb-2">
      <v-form
        v-model="isFormValid"
        ref="form"
        form="form"
        id="form"
        @submit.prevent="save()"
      >
        <v-row>
          <v-col cols="6" class="py-0">
            <v-text-field
              v-model="contactoNombre"
              dense
              label="Nombre"
              outlined
              :rules="
                rules.required.concat(
                  [rules.maxLength(contactoNombre, 50)],
                  [rules.requiredTrim(contactoNombre)]
                )
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="py-0 px-0">
            <v-subheader class="py-0 px-0">
              <v-switch
                class="ml-4"
                v-model="informarActualizacion"
                dense
                outlined
              ></v-switch>
              Informar actualización
            </v-subheader>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              v-model="contactoEmail"
              dense
              label="Email"
              outlined
              :rules="
                rules.required.concat([
                  rules.requiredTrim(contactoEmail),
                  rules.maxLength(contactoEmail, 50),
                  rules.validEmail,
                ])
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              v-model="contactoTelefono"
              dense
              label="Teléfono"
              outlined
              :rules="
                rules.required.concat(
                  [rules.maxLength(contactoTelefono, 50)],
                  [rules.requiredTrim(contactoTelefono)]
                )
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-textarea
              v-model="observaciones"
              label="Observaciones"
              dense
              auto-grow
              rows="1"
              :rules="[rules.maxLength(observaciones, 100)]"
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined @click="close()">
        <span align="center"> Cancelar </span>
      </v-btn>
      <v-btn
        color="#195472"
        type="submit"
        :disabled="!isFormValid"
        form="form"
        :loading="isLoadingSaveBtn"
      >
        <span class="myLabel" align="center">Guardar</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";

export default {
  name: "EditContactoCliente",
  directives: { mask },
  props: {
    contactoId: {
      type: Number,
      required: false,
    },
    clienteId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formEditTitle: "Nuevo contacto",
      isFormValid: false,
      rules: rules,
      contactoNombre: null,
      informarActualizacion: false,
      contactoEmail: null,
      contactoTelefono: null,
      observaciones: null,
      isLoadingSaveBtn: false,
    };
  },
  created() {
    if (this.contactoId != null) this.setContactoToEdit();
  },
  methods: {
    ...mapActions({
      getContactoById: "clientesData/getContactoById",
      saveContactoCliente: "clientesData/saveContactoCliente",
      setAlert: "user/setAlert",
    }),
    async setContactoToEdit() {
      this.formEditTitle = "Editar contacto";
      try {
        const response = await this.getContactoById(this.contactoId);
        this.contactoNombre = response.contactoNombre;
        this.observaciones = response.descripcion;
        this.informarActualizacion = response.informarActualizacion;
        this.contactoEmail = response.email;
        this.contactoTelefono = response.telefono;
      } catch (error) {
        console.error(error);
      }
    },
    async save() {
      this.isLoadingSaveBtn = true;
      const data = {
        contactoId: this.contactoId != null ? this.contactoId : 0,
        contactoNombre: this.contactoNombre,
        descripcion: this.observaciones,
        email: this.contactoEmail,
        telefono: this.contactoTelefono,
        informarActualizacion: this.informarActualizacion,
        clienteId: this.clienteId,
      };
      try {
        const response = await this.saveContactoCliente(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.$emit("closeAndReload", true);
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingSaveBtn = false;
    },
    close() {
      this.$emit("closeAndReload", false);
    },
  },
};
</script>

<style scoped>
::v-deep .myLabel {
  color: white;
}
</style>
